<template>
  <div class="device-container">
    <div class="title-heading">
      <p>{{ $t("Rate Your Experience") }}</p>
    </div>
    <div class="rate">
      <div v-for="(star, index) in maxStars" :key="index" @click="updateRating(index)">
        <img :src="starImage(index)" :alt="starAltText(star)" />
      </div>
    </div>
    <div class="sub-heading">
      <!-- <p>{{ $t("Please enter some feedback to improve us") }}</p> -->
      <p style="color:red" v-if="errormessage">{{ $t(errormessage) }}</p>
    </div>
    <div class="text-box">
      <textarea :value="feedback" @input="feedback = $event.target.value" class="comment-box" rows="10" cols="50" maxlength="256"
        :placeholder="
          `${this.$t('Tell us about your experience with CSPACE')}`
          
        "></textarea>
    </div>
    <button class="button-3 button-primary" @click="submitData">
      {{$t("submit")}}
    </button>

    <Loading v-if="isLoaderActive"></Loading>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import { showSnackBar } from "@/utilities";

export default {
  props: {
    maxStars: {
      type: Number,
      default: 5
    },
    closePopup: {
      type: false,
    },
    rating: {
      type: Number,
      default: 0
    }
  },
  data() {
    const stars = Array(this.maxStars).fill(0);
    stars[0] = 2;

    return {
      stars,
      message: "",
      feedback: "",
      counter: 1,
      feedbackMessage: "",
      feedbackplaceholder: this.$t("Tell us about your experience with Saina Play"),
      isLoaderActive: false,
      responseactive:false,
      errormessage:"",
      closePopup:"",
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
    };
  },

  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
  },
  computed: {
    // rating() {
    //   let total = 0;
    //   for (let i = 0; i < this.stars.length; i++) {
    //     total += this.stars[i];
    //   }
    //   const rating = total / this.maxStars;
    //   return Math.round(rating * 2) / 2;
    // }
  },
  methods: {
    ...mapActions(["submitfeedback"]),
    updateRating(index) {
      if (this.stars[index] === 1) {
        // Clicked on a half star, set to full rating
        this.stars.splice(index, 1, 2);
      } else {
        // Clicked on an empty or full star, set to half rating
        this.stars.splice(index, 1, 1);
        for (let i = index - 1; i >= 0; i--) {
          this.stars.splice(i, 1, 2);
        }
        for (let i = index + 1; i < this.maxStars; i++) {
          this.stars.splice(i, 1, 0);
        }
      }

      for (let star in this.stars) {
        if (star == 0) continue
        if (this.stars[star] == 2) {
          this.counter = this.counter + 1;
        } else if (this.stars[star] == 1) {
          this.counter = this.counter + 0.5;
        }
      }

      this.$emit('rating-updated', this.rating);
    },

    starImage(index) {
      if (this.stars[index] === 0) {
        return require('@/assets/icons/emptyrateGroup 6028.svg');
      } else if (this.stars[index] === 1) {
        return require('@/assets/icons/halfrate.svg');
      } else {
        return require('@/assets/icons/fullrate.svg');
      }
    },
    starAltText(starValue) {
      if (starValue === 0) {
        return 'Empty star';
      } else if (starValue === 1) {
        return 'Half star';
      } else {
        return 'Full star';
      }
    },

    submitData() {
      this.isLoaderActive = true;
      const data = {
        rating: this.counter,
        feedback: this.feedback,
      };
      this.submitfeedback(data).then((data) => {
        const success = data.data.success;
        if (success) {
          showSnackBar(this.$t("Feedback submitted successfully!"));
          this.feedback = '';
          this.stars = [2,0,0,0,0]
          this.counter = 1;
          this.errormessage = ""
        } else {
          showSnackBar(this.$t(data.data.reason));
          this.closePopup = false,
          this.counter = 1;
          this.stars = [2,0,0,0,0]
        }
        this.isLoaderActive = false;
       
        

      })
        .catch(() => {
          // show error message
          this.isLoaderActive = false;
          

          showSnackBar(this.$t("Please check your internet connection and try again."));
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.rate {
  margin-top: 20px;
  display: flex;
}

.rate div {
  margin: 0 5px;
  cursor: pointer;
}

.rate img {
  width: 30px;
  height: 30px;
}

.title-heading {
  color: $clr-saina-light-dark-txt;
}

.sub-heading {
  color: #5c5c5d;
  margin-top: 20px;
}

// .comment-box {
//   width: 50%;
//   height: 85px;
//   margin: 0.4rem 0;
//   padding: 0.9rem;
//   background-color: transparent;
//   border: 1px solid #5c5c5d;
//   border-radius: 0.1rem;
//   caret-color: #5c5c5d;
//   font-family: "Open Sans", sans-serif;
//   font-size: 14px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 18px;
//   resize: vertical;
//   overflow: auto;
// }

.comment-box:focus {
  outline: none;
  border-color:$clr-saina-light-dark-main-bg;
}

/* Button 3 styles */
.button-3 {
  // background: linear-gradient(89.98deg, #B1853E 0.01%, #DFC46B 17.47%, #FAF7D6 47.35%, #DFC46B 81.62%, #B1853E 99.18%);
  padding: 10px 20px;
  width: 11rem;
  // color:#151618;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  &:hover {
        background: $clr-saina-light-dark-main-bg;
        color:$clr-saina-light-dark-bg;
        // border:2px solid #121212;
    }
}

.device-container {
  padding: 1.5em 3em 2rem;

  h4 {
    color: $clr-light-gd4;

    span {
      color: $clr-light-gd3;
    }
  }
}
.confirmation {
    padding: 3rem;
    border-radius: 10px;
    &-title {
      color: $clr-saina-light-dark-txt;
      font-family: $font-regular;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 29px;
      text-align: center;
      margin-bottom: 20px;
      span {
        font-weight: bold;
      }
    }
    &-btn {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      &-align {
        width: 45%;
      }
    }


  }
  @media only screen and (max-width: 767px) {
  .comment-box {
    width: 100%;
    height: 100px;
    line-height: 20px;
  }
}

/* For devices with a screen width of 768px or more */
  @media only screen and (min-width: 768px) {
  .comment-box {
    width: 50%;
    height: 110px;
  }
}
  
</style>
